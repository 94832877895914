import React from "react"
import Layout from "../components/layout"

export default function Culture() {
  return (
    <Layout>
      <section className="page">
        <h2 className="page__heading">Culture</h2>
        <h3 className="page__heading--section">Our Mission</h3>
        <p className="page__body">
        Squirrel is about accessible adventure. We believe an on-demand, compact, fully furnished luxury motorhome that gives us the freedom to do what we want, when we want, is the answer.
        </p>
        <p className="page__body">
          Sincerely,
          <br />
          Team Squirrel
        </p>
      </section>
    </Layout>
  )
}
